import root from "window-or-global";
import type {LocaleString} from "@atg-shared/market-config";
import * as Storage from "@atg-shared/storage";
import {i18n, type Messages, type AllMessages} from "@lingui/core";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import system from "@atg-shared/system";
import calendar from "dayjs/plugin/calendar";
import LocalizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(LocalizedFormat);
dayjs.extend(updateLocale);
dayjs.extend(calendar);

const {availableLocales, defaultLocale} = root.marketConfig;

function isValidLocale(locale: string): locale is LocaleString {
    return locale.length > 0 && availableLocales.includes(locale as LocaleString);
}

export const SELECTED_LANGUAGE_KEY = "SELECTED_LANGUAGE";

export const LOCALE_CHANGE_EVENT_TYPE = "LOCALE_CHANGE";

export class LocaleChangeEvent extends CustomEvent<{locale: LocaleString}> {
    constructor(locale: LocaleString) {
        super(LOCALE_CHANGE_EVENT_TYPE, {detail: {locale}});
    }
}

function isAllMessages(messages: Messages | AllMessages): messages is AllMessages {
    return availableLocales.every((availableLocale) => availableLocale in messages);
}

export type LoadMessages = (
    locale: LocaleString,
) => (AllMessages | Promise<AllMessages>) | (Messages | Promise<Messages>);

async function initI18n(locale: LocaleString, loadMessages: LoadMessages) {
    const messages = await loadMessages(locale);

    if (isAllMessages(messages)) {
        i18n.load(messages);
    } else {
        i18n.load(locale, messages);
    }

    i18n.activate(locale);
}

async function initDayjs(locale: LocaleString) {
    const shortLocale = locale.split("-")[0];

    switch (shortLocale) {
        case "sv": {
            await import(/* webpackChunkName: "dayjs-locale-sv" */ "dayjs/locale/sv");
            dayjs.locale("sv");
            dayjs.updateLocale("sv", {
                calendar: {
                    lastDay: "[igår] HH:mm",
                    sameDay: "[idag] HH:mm",
                    nextDay: "[imorgon] HH:mm",
                    lastWeek: "[i] dddd[s] HH:mm",
                    nextWeek: "dddd HH:mm",
                    sameElse: "YYYY-MM-DD",
                },
            });
            return;
        }
        case "fi": {
            await import(/* webpackChunkName: "dayjs-locale-fi" */ "dayjs/locale/fi");
            dayjs.locale("fi");
            dayjs.updateLocale("fi", {
                calendar: {
                    lastDay: "[eilen] HH.mm",
                    sameDay: "[tänään] HH.mm",
                    nextDay: "[huomenna] HH.mm",
                    lastWeek: "[viime] dddd[s] HH.mm",
                    nextWeek: "dddd HH.mm",
                    sameElse: "YYYY.MM.DD",
                },
            });
            return;
        }
        case "pseudo": {
            await import(
                /* webpackChunkName: "dayjs-locale-x-pseudo" */ "dayjs/locale/x-pseudo"
            );
            dayjs.locale("x-pseudo");
            dayjs.updateLocale("x-pseudo", {
                calendar: {
                    lastDay: "[ïϱåř] HH.mm",
                    sameDay: "[ïδáϱ] HH.mm",
                    nextDay: "[ï₥ôřϱôñ] HH.mm",
                    lastWeek: "[ï] dddd[s] HH.mm",
                    nextWeek: "dddd HH.mm",
                    sameElse: "YYYY.MM.DD",
                },
            });
            return;
        }
        default: {
            throw new Error(`Unsupported locale: ${shortLocale}`);
        }
    }
}

export function getLanguage() {
    const storedLanguage = Storage.getItem(SELECTED_LANGUAGE_KEY);
    const language =
        storedLanguage && isValidLocale(storedLanguage) ? storedLanguage : defaultLocale;
    return language;
}

export async function initialiseLanguage(loadMessages: LoadMessages) {
    const locale = getLanguage();

    await Promise.all([initI18n(locale, loadMessages), initDayjs(locale)]);

    const handleLocaleChange = async (event: LocaleChangeEvent) => {
        const {locale: newLocale} = event.detail;
        await Promise.all([initI18n(newLocale, loadMessages), initDayjs(newLocale)]);
        if (isValidLocale(newLocale) && newLocale !== i18n.locale) {
            i18n.activate(newLocale);
        }
    };

    if (system.isWeb) {
        // @ts-expect-error
        root.addEventListener(LOCALE_CHANGE_EVENT_TYPE, handleLocaleChange);
    }
}

export function getLanguages() {
    return root.marketConfig.availableLocales;
}
