import "@atg-shared/micro-frontend/setPublicPath";
import log, {serverLogPlugin} from "@atg-shared/log";
import {configureStoreForAuthentication} from "@atg-shared/auth";
import {initialiseLanguage} from "@atg-shared/language";
import {configureEmotionCache} from "atg-emotion-cache";
import {store as playStore} from "./store";

serverLogPlugin(log, "play");
log.setLevel("warn");
configureStoreForAuthentication(playStore);
configureEmotionCache("play");

initialiseLanguage(async (locale) => {
    const {messages} = await import(
        /* webpackChunkName: "i18n" */ `../locales/${locale}/messages.po`
    );
    return messages;
});

export const App = () => import(/* webpackChunkName: "App" */ "./components/App");

export const VideoFrame = () =>
    import(/* webpackChunkName: "VideoFrame" */ "@atg-play/components/miniPlayer");

export const StartlistArticles = () =>
    import(
        /* webpackChunkName: "StartlistArticles" */ "@atg-play-shared/startlist-articles"
    );
